<template>
  <div id="appRoot">
    <template v-if="!$route.meta.public">
      <v-app id="inspire" class="app">
        <v-form ref="form" lazy-validation>
          <app-drawer class="app--drawer"></app-drawer>
          <app-toolbar class="app--toolbar" v-bind:class="{ 'toolbar-expanded': sidebarExpanded}"></app-toolbar>
          <v-main v-bind:class="{ 'v-main-expanded': !sidebarExpanded}">
            <!-- Page Header -->
            <page-header v-if="$route.meta.breadcrumb"></page-header>
            <div class="page-wrapper">
              <router-view></router-view>
            </div>
            <!-- App Footer -->
            <v-footer height="auto" class="white pa-4 pt-16 app--footer">
              <span class="caption">khoquangchau.vn Design &copy; 2021</span>
              <v-spacer></v-spacer>
            </v-footer>
          </v-main>
          <!-- Go to top -->
          <app-fab></app-fab>
          <v-snackbar transition="slide-x-transition" :timeout="snackbar.timeout ? snackbar.timeout : 3000" :color="snackbar.color" v-model="snackbar.show" bottom left multi-line rounded>
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar.show = false"
              >
                X
              </v-btn>
            </template>
          </v-snackbar>
          <v-dialog v-model="delDialog" persistent max-width="320">
            <v-card>
              <v-toolbar flat dark height="35" color="oee-theme">
                <v-btn icon dark @click="delDialog = false">
                  <v-icon>fa-times-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <span>Xác nhận</span>
              </v-toolbar>
              <v-card-text style="margin-top: 20px">{{ delMessage || 'Bạn có chắc chắn muốn xóa mục đã chọn?'}}</v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="blue darken-1 white--text" @click="delConfirmed">Đồng ý</v-btn>
                <v-btn color="gray darken-1" @click="delDialog = false">Hủy</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
      </v-app>
    </template>
    <template v-else>
      <transition>
        <keep-alive>
          <router-view :key="$route.fullpath"></router-view>
        </keep-alive>
      </transition>
    </template>
  </div>
</template>

<script>
import AppDrawer from "@/components/AppDrawer";
import AppToolbar from "@/components/AppToolbar";
import AppFab from "@/components/AppFab";
import PageHeader from "@/components/PageHeader";
import AppEvents from "./event";
import { mapState } from "vuex";
export default {
  components: {
    AppDrawer,
    AppToolbar,
    AppFab,
    PageHeader
  },
  data: () => ({
    confirmAction: "",
    delMessage: "",
    delDialog: false,
    delId: 0,
    rightDrawer: false,
    snackbar: {
      show: false,
      text: "",
      color: ""
    }
  }),
  computed: {
    ...mapState("commons", {
      sidebarExpanded: "sidebarExpanded"
    })
  },
  methods: {
    delConfirmed() {
      this.delDialog = false;
      if (this.confirmAction != '') {
        this.$root.$emit('CONFIRMED_ACTION', {action: this.confirmAction, id: this.delId});
      }
      else {
        this.$root.$emit('CONFIRMED_REMOVE_DATA', this.delId);
      }
      this.delId = 0;
      this.confirmAction = "";
    }
  },
  created() {
    AppEvents.forEach(item => {
      this.$on(item.name, item.callback);
    });
    window.getApp = this;
    window.getApp.$on("APP_DRAWER_TOGGLED", () => {
      this.$store.commit("commons/setSidebarExpanded", {
        expanded: !this.sidebarExpanded
      });
    });
  },
  mounted() {
    if(this.$store.state.authentication.token != "") {
      this.$store.dispatch("roles/getList");
    }
  }
};
</script>

<style lang="stylus" scoped>
.setting-fab {
  top: 50% !important;
  right: 0;
  border-radius: 0;
}

.page-wrapper {
  min-height: calc(100vh - 64px - 50px - 81px);
}

.toolbar-expanded {
  padding: 0 0 0 230px !important;
}

.v-snack__wrapper p {
  margin: 0 0 0 0 !important;
}
</style>
