export default [

  {
    path: '*',
    meta: {
      public: true,
    },
    redirect: {
      path: '/404'
    }
  },
  {
    path: '/404',
    meta: {
      public: true,
    },
    name: 'NotFound',
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/NotFound.vue`
    )
  },
  {
    path: '/403',
    meta: {
      public: true,
    },
    name: 'AccessDenied',
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/Deny.vue`
    )
  },
  {
    path: '/500',
    meta: {
      public: true,
    },
    name: 'ServerError',
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/Error.vue`
    )
  },
  {
    path: '/login',
    meta: {
      public: true,
    },
    name: 'Login',
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/Login.vue`
    )
  },
  {
    path: '/',
    meta: {roleId: 1},
    name: 'Root',
    redirect: {
      name: 'Dashboard'
    }
  },
  {
    path: '/profile',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 1},
    name: 'Profile',
    component: () => import(
      `@/views/Profile.vue`
    )
  },
  {
    path: '/dashboard',
    meta: { title: 'Bảng điều khiển', breadcrumb: true, requiresAuth: true, roleId: 1 },
    name: 'Dashboard',
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/Dashboard.vue`
    )
  },
  {
    path: '/don-hang/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, createButtonText: 'Tạo đơn mới', saveButton: true, saveButtonText: 'Cập nhật', saveButtonIcon: 'fas fa-align-left', refreshButton: true, roleId: 32 },
    name: 'OrderList',
    component: () => import(
      `@/views/Order/OrderList.vue`
    )
  },
  {
    path: '/don-hang/:Pid',
    meta: { breadcrumb: false, requiresAuth: true, roleId: 4096 },
    name: 'OrderDetail',
    component: () => import(
      `@/views/Order/OrderDetail.vue`
    )
  },
  {
    path: '/khach-hang/quan-ly-khach-hang',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 8 },
    name: 'CustomerList',
    component: () => import(
      `@/views/Customer/CustomerList.vue`
    )
  },
  {
    path: '/khach-hang/quan-ly-khach-hang/:Pid',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, createButton: true, createButtonText: 'Reset password', createButtonIcon: 'fa-redo', roleId: 8 },
    name: 'CustomerDetail',
    component: () => import(
      `@/views/Customer/CustomerDetail.vue`
    )
  },
  {
    path: '/khach-hang/lich-su-nap-tien/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 8 },
    name: 'RechargeHistory',
    component: () => import(
      `@/views/RechargeHistory/list.vue`
    )
  },
  {
    path: '/khach-hang/lich-su-nap-tien/:Pid',
    meta: { title: 'Chi tiết nạp tiền', breadcrumb: true, requiresAuth: true, saveButton: false, roleId: 8 },
    name: 'RechargeDetail',
    component: () => import(
      `@/views/RechargeHistory/detail.vue`
    )
  },
  {
    path: '/nap-tien/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, saveButtonText: 'NẠP TIỀN', roleId: 16 },
    name: 'RechargeNew',
    component: () => import(
      `@/views/RechargeHistory/list.vue`
    )
  },
  {
    path: '/khach-hang/lich-su-thanh-toan/',
    meta: { breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 8 },
    name: 'PaymentHistory',
    component: () => import(
      `@/views/PaymentHistory/PaymentHistoryList.vue`
    )
  },
  {
    path: '/khach-hang/lich-su-rut-tien/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 8 },
    name: 'WithdrawHistory',
    component: () => import(
      `@/views/WithdrawHistory/list.vue`
    )
  },
  {
    path: '/ke-toan/thanh-toan-don-hang/',
    meta: { title: 'Thanh toán tiền hàng', breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 128 },
    name: 'PayOrderList',
    component: () => import(
      `@/views/PayOrder/list.vue`
    )
  },
  {
    path: '/quan-ly-ma-van-don/',
    meta: { breadcrumb: true, requiresAuth: true, refreshButton: true, createButton: true, createButtonText: ' Export excel', createButtonIcon: 'fa-file-excel-o', saveButton: true, saveButtonText: 'Import excel', saveButtonIcon: 'fa-file-excel', roleId: 512 },
    name: 'Transaction',
    component: () => import(
      `@/views/Transaction/list.vue`
    )
  },
  {
    path: '/ky-gui/',
    meta: { breadcrumb: true, requiresAuth: true, refreshButton: true, createButton: true, createButtonText: 'Tạo đơn mới', roleId: 32 },
    name: 'Deposit',
    component: () => import(
      `@/views/Deposit/list.vue`
    )
  },
  {
    path: '/ky-gui/:Pid',
    meta: { title: 'Chi tiết ký gửi', breadcrumb: true, requiresAuth: true, saveButton: false, roleId: 32 },
    name: 'DepositDetail',
    component: () => import(
      `@/views/Deposit/detail.vue`
    )
  },
  {
    path: '/khieu-nai/',
    meta: { breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 4096 },
    name: 'Complain',
    component: () => import(
      `@/views/Complain/list.vue`
    )
  },
  {
    path: '/khieu-nai/:Pid',
    meta: { title: 'Chi tiết khiếu nại', breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 4096 },
    name: 'ComplainDetail',
    component: () => import(
      `@/views/Complain/detail.vue`
    )
  },
  {
    path: '/quan-ly-kho/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, saveButton: true, saveButtonText: 'Import excel', saveButtonIcon: 'fa-file-excel', refreshButton: true, roleId: 1024 },
    name: 'StoreManager',
    component: () => import(
      `@/views/StoreManager/list.vue`
    )
  },
  {
    path: '/nhap-kho-vn/bao-hang/',
    meta: {title: 'Nhập bao hàng VN', breadcrumb: true, requiresAuth: true, createButton: true, createButtonText: 'Nhập kho', refreshButton: true, otherButton: true, otherButtonText: ' Nhập bao', otherButtonIcon: 'fa-database', roleId: 256 },
    name: 'StoreManagerVN',
    component: () => import(
      `@/views/StoreManagerVn/list.vue`
    )
  },
  {
    path: '/nhap-kho-vn/bao-hang/:Pid',
    meta: {title: 'Chi tiết nhập bao hàng', breadcrumb: true, requiresAuth: true, createButton: true, createButtonText: 'Nhập bao mới', saveButton: true, saveButtonText: 'Bắn mã', saveButtonIcon: 'fas fa-barcode', roleId: 256 },
    name: 'StoreManagerVNDetail',
    component: () => import(
      `@/views/StoreManagerVn/detail.vue`
    )
  },
  {
    path: '/nhap-kho-vn/ma-bill/',
    meta: {title: 'Nhập kiện VN', breadcrumb: true, requiresAuth: true, createButton: true, createButtonText: 'Nhập kho', refreshButton: true, otherButton: true, otherButtonText: ' Export excel', otherButtonIcon: 'fa-file-excel-o', roleId: 256 },
    name: 'SmallpackageImportVN',
    component: () => import(
      `@/views/StoreManagerVn/smallpackage_list.vue`
    )
  },
  {
    path: '/quan-ly-bao-hang/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, saveButton: true, saveButtonText: 'Import excel', saveButtonIcon: 'fa-file-excel', otherButton: true, otherButtonText: ' Export excel', otherButtonIcon: 'fa-file-excel-o', refreshButton: true, roleId: 1024 },
    name: 'BigPackage',
    component: () => import(
      `@/views/BigPackage/list.vue`
    )
  }, 
  // {
  //   path: '/xuat-kho-tq/bao-hang/',
  //   meta: { title: 'Xuất bao hàng kho TQ', breadcrumb: true, requiresAuth: true, createButton: true, createButtonText: 'Xuất kho', refreshButton: true, roleId: 2048 },
  //   name: 'ChinaBigpackageExport',
  //   component: () => import(
  //     `@/views/ChinaExport/bigpackage.vue`
  //   )
  // },
  // {
  //   path: '/xuat-kho-tq/ma-van-don/',
  //   meta: { title: 'Xuất kiện hàng kho TQ', breadcrumb: true, requiresAuth: true, createButton: true, createButtonText: 'Xuất kho', refreshButton: true, roleId: 2048 },
  //   name: 'ChinaSmallpackageExport',
  //   component: () => import(
  //     `@/views/ChinaExport/smallpackage.vue`
  //   )
  // },
  {
    path: '/xuat-kho-tq/',
    meta: { title: 'Xuất kho TQ', breadcrumb: true, requiresAuth: true, createButton: false, refreshButton: false, roleId: 1024 },
    name: 'ChinaExport',
    component: () => import(
      `@/views/ChinaExport/detail.vue`
    )
  },
  {
    path: '/xuat-kho/ma-van-don',
    meta: { breadcrumb: true, requiresAuth: true, createButton: false, refreshButton: true, roleId: 256 },
    name: 'Deliverable',
    component: () => import(
      `@/views/Deliverable/list.vue`
    )
  },
  {
    path: '/xuat-kho/phieu-xuat-kho',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 256 },
    name: 'ExportReceipt',
    component: () => import(
      `@/views/ExportReceipt/list.vue`
    )
  },
  {
    path: '/xuat-kho/xuat-phieu',
    meta: { title: 'Xuất kho VN', breadcrumb: true, requiresAuth: true, createButton: false, refreshButton: false, roleId: 256 },
    name: 'StoreExport',
    component: () => import(
      `@/views/StoreExport/detail.vue`
    )
  },
  {
    path: '/ke-toan/kiem-soat-don-hang/',
    meta: { title: 'Kiểm soát đơn hàng',  breadcrumb: true, requiresAuth: true, createButton: true, createButtonText: ' Export', createButtonIcon: 'fa-file-excel-o', refreshButton: true, roleId: 128 },
    name: 'AccountantOrder',
    component: () => import(
      `@/views/AccountantOrder/list.vue`
    )
  },
  {
    path: '/ke-toan/luong-thu-chi/',
    meta: { title: 'Luồng thu chi (¥)', breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 128 },
    name: 'AccountantCumulationList',
    component: () => import(
      `@/views/AccountantCumulation/list.vue`
    )
  },
  {
    path: '/ke-toan/luong-thu-chi/:Pid',
    meta: {title: 'Chi tiết thu/chi (¥)', breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 128 },
    name: 'AccountantCumulationDetail',
    component: () => import(
      `@/views/AccountantCumulation/detail.vue`
    )
  },
  {
    path: '/ke-toan/luong-thu-chi-vnd/',
    meta: { title: 'Luồng thu chi (₫)', breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 128 },
    name: 'AccountantCumulationVNDList',
    component: () => import(
      `@/views/AccountantCumulationVND/list.vue`
    )
  },
  {
    path: '/ke-toan/luong-thu-chi-vnd/:Pid',
    meta: {title: 'Chi tiết thu/chi (₫)', breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 128 },
    name: 'AccountantCumulationVNDDetail',
    component: () => import(
      `@/views/AccountantCumulationVND/detail.vue`
    )
  },
  {
    path: '/ke-toan/hoan-tien-tuikuan/',
    meta: { title: 'Shop hoàn tiền KN', breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 128 },
    name: 'TuiKuanList',
    component: () => import(
      `@/views/TuiKuan/list.vue`
    )
  },
  {
    path: '/ke-toan/hoan-tien-tuikuan/:Pid',
    meta: {title: 'Chi tiết hoàn tiền', breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 128 },
    name: 'TuiKuanDetail',
    component: () => import(
      `@/views/TuiKuan/detail.vue`
    )
  },
  //==================CAI DAT========================
  {
    path: '/cai-dat/phi-can-nang/',
    meta: { title: 'Phí cân nặng', breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 2 },
    name: 'FeeWeightList',
    component: () => import(
      `@/views/FeeWeight/list.vue`
    )
  },
  {
    path: '/cai-dat/phi-can-nang/:Pid',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 2 },
    name: 'FeeWeightDetail',
    component: () => import(
      `@/views/FeeWeight/detail.vue`
    )
  },
  {
    path: '/cai-dat/phi-khoi/',
    meta: { title: 'Phí khối', breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 2 },
    name: 'FeeVolumeList',
    component: () => import(
      `@/views/FeeVolume/list.vue`
    )
  },
  {
    path: '/cai-dat/phi-khoi/:Pid',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 2 },
    name: 'FeeVolumeDetail',
    component: () => import(
      `@/views/FeeVolume/detail.vue`
    )
  },
  {
    path: '/cai-dat/phi-kiem-dem/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 2 },
    name: 'FeeCheckList',
    component: () => import(
      `@/views/FeeCheck/list.vue`
    )
  },
  {
    path: '/cai-dat/phi-kiem-dem/:Pid',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 2 },
    name: 'FeeCheckDetail',
    component: () => import(
      `@/views/FeeCheck/detail.vue`
    )
  },
  {
    path: '/cai-dat/phi-bao-hiem/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 2 },
    name: 'FeeGuaranteeList',
    component: () => import(
      `@/views/FeeGuarantee/list.vue`
    )
  },
  {
    path: '/cai-dat/phi-bao-hiem/:Pid',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 2 },
    name: 'FeeGuaranteeDetail',
    component: () => import(
      `@/views/FeeGuarantee/detail.vue`
    )
  },
  {
    path: '/cai-dat/phi-gia-co/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 2 },
    name: 'FeeReinforceList',
    component: () => import(
      `@/views/FeeReinforce/list.vue`
    )
  },
  {
    path: '/cai-dat/phi-gia-co/:Pid',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 2 },
    name: 'FeeReinforceDetail',
    component: () => import(
      `@/views/FeeReinforce/detail.vue`
    )
  },
  {
    path: '/cai-dat/phi-mua-hang/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 2 },
    name: 'FeeBuyList',
    component: () => import(
      `@/views/FeeBuy/list.vue`
    )
  },
  {
    path: '/cai-dat/phi-mua-hang/:Pid',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 2 },
    name: 'FeeBuyDetail',
    component: () => import(
      `@/views/FeeBuy/detail.vue`
    )
  },
  {
    path: '/cai-dat/cau-hinh-chung',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 2, reportRoleId: 128},
    name: 'Configuration',
    component: () => import(
      `@/views/Configuration/detail.vue`
    )
  },
  {
    path: '/cai-dat/nhan-vien/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 2, reportRoleId: 128 },
    name: 'StaffList',
    component: () => import(
      `@/views/Staff/list.vue`
    )
  },
  {
    path: '/cai-dat/nhan-vien/:Pid',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 2, reportRoleId: 128 },
    name: 'StaffDetail',
    component: () => import(
      `@/views/Staff/detail.vue`
    )
  },
  {
    path: '/cai-dat/quan-ly-level/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 8 },
    name: 'CustomerLevel',
    component: () => import(
      `@/views/CustomerLevel/CustomerLevelList.vue`
    )
  },
  {
    path: '/cai-dat/quan-ly-level/:Pid',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 8 },
    name: 'CustomerLevelDetail',
    component: () => import(
      `@/views/CustomerLevel/CustomerLevelDetail.vue`
    )
  },
  {
    path: '/cai-dat/quan-ly-kho-tq/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 8 },
    name: 'ChinaStoreList',
    component: () => import(
      `@/views/ChinaStore/list.vue`
    )
  },
  {
    path: '/cai-dat/quan-ly-kho-tq/:Pid',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 8},
    name: 'ChinaStoreDetail',
    component: () => import(
      `@/views/ChinaStore/detail.vue`
    )
  },
  {
    path: '/cai-dat/quan-ly-kho/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 8 },
    name: 'WarehouseList',
    component: () => import(
      `@/views/Warehouse/list.vue`
    )
  },
  {
    path: '/cai-dat/quan-ly-kho/:Pid',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 8 },
    name: 'WarehouseDetail',
    component: () => import(
      `@/views/Warehouse/detail.vue`
    )
  },
  {
    path: '/cai-dat/bang-gia-van-chuyen/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 8 },
    name: 'PricesList',
    component: () => import(
      `@/views/Prices/list.vue`
    )
  },
  {
    path: '/cai-dat/bang-gia-van-chuyen/:Pid',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 8 },
    name: 'PricesDetail',
    component: () => import(
      `@/views/Prices/detail.vue`
    )
  },
  {
    path: '/cai-dat/hashtag/',
    meta: { breadcrumb: true, requiresAuth: true, createButton: true, refreshButton: true, roleId: 2 },
    name: 'FlagList',
    component: () => import(
      `@/views/Flag/list.vue`
    )
  },
  {
    path: '/cai-dat/hashtag/:Pid',
    meta: { breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 2 },
    name: 'FlagDetail',
    component: () => import(
      `@/views/Flag/detail.vue`
    )
  },
  {
    path: '/thong-bao/',
    meta: { title: 'Thông báo', breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 1 },
    name: 'NotificationList',
    component: () => import(
      `@/views/Notification/list.vue`
    )
  },
  {
    path: '/thong-bao-khach-hang/',
    meta: { title: 'Thông báo khách hàng', breadcrumb: true, requiresAuth: true, createButton: false, refreshButton: false, roleId: 16384 },
    name: 'ZNSNotification',
    component: () => import(
      `@/views/ZNSNotification/detail.vue`
    )
  },
  //==================BAO CAO========================
  {
    path: '/bao-cao/loi-nhuan-dat-hang/',
    meta: { title: 'Thống kê lợi nhuận đặt hàng', breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 4, reportRoleId: 1 },
    name: 'thong-ke-loi-nhuan-dat-hang',
    component: () => import(
      `@/views/Reports/thong-ke-loi-nhuan-dat-hang.vue`
    )
  },
  {
    path: '/bao-cao/phi-mua-hang/',
    meta: { title: 'Thống kê phí mua hàng', breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 4, reportRoleId: 2 },
    name: 'thong-ke-phi-mua-hang',
    component: () => import(
      `@/views/Reports/thong-ke-phi-mua-hang.vue`
    )
  },
  {
    path: '/bao-cao/phi-van-chuyen/',
    meta: { title: 'Thống kê phí vận chuyển', breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 4, reportRoleId: 4 },
    name: 'thong-ke-phi-van-chuyen',
    component: () => import(
      `@/views/Reports/thong-ke-phi-van-chuyen.vue`
    )
  },
  {
    path: '/bao-cao/tien-te/',
    meta: { title: 'Thống kê tiền tệ', breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 4, reportRoleId: 8 },
    name: 'thong-ke-tien-te',
    component: () => import(
      `@/views/Reports/thong-ke-tien-te.vue`
    )
  },
  {
    path: '/ke-toan/tai-chinh/',
    meta: { title: 'Tài chính', breadcrumb: true, requiresAuth: true, roleId: 4, reportRoleId: 16, otherButton: true, otherButtonText: 'Xem log', otherButtonIcon: 'fa-book', saveButton: true, saveButtonText: 'Chuyển tiền', saveButtonIcon: 'fa-redo', createButton: true, createButtonText: 'Thu Chi', createButtonIcon: 'fa-money-bill-alt'},
    name: 'bao-cao-tai-chinh',
    component: () => import(
      `@/views/Reports/bao-cao-tai-chinh.vue`
    )
  },
  {
    path: '/bao-cao/nap-tien/',
    meta: { title: 'Thống kê phí vận chuyển', breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 4, reportRoleId: 8 },
    name: 'bao-cao-nap-tien',
    component: () => import(
      `@/views/Reports/bao-cao-nap-tien.vue`
    )
  },
  {
    path: '/bao-cao/tong-hop/',
    meta: { title: 'Báo cáo doanh thu', breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 4, reportRoleId: 8 },
    name: 'bao-cao-tong-hop',
    component: () => import(
      `@/views/Reports/bao-cao-tong-hop.vue`
    )
  },
  {
    path: '/bao-cao/bao-cao-loi-nhuan-khach-hang/',
    meta: { title: 'Báo cáo chi tiết khách hàng', breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 4, reportRoleId: 8 },
    name: 'bao-cao-loi-nhuan-khach-hang',
    component: () => import(
      `@/views/Reports/bao-cao-loi-nhuan-khach-hang.vue`
    )
  },
  {
    path: '/bao-cao/cong-no/',
    meta: { title: 'Báo cáo công nợ', breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 4, reportRoleId: 8 },
    name: 'bao-cao-cong-no',
    component: () => import(
      `@/views/Reports/bao-cao-cong-no.vue`
    )
  },
  {
    path: '/bao-cao/cong-no/:Pid',
    meta: { title: 'Báo cáo công nợ khách hàng', breadcrumb: true, requiresAuth: true, roleId: 4, reportRoleId: 8 },
    name: 'bao-cao-cong-no-chi-tiet',
    component: () => import(
      `@/views/Reports/bao-cao-cong-no-chi-tiet.vue`
    )
  },
  {
    path: '/bao-cao/doanh-thu/',
    meta: { title: 'Báo cáo doanh thu', breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 4, reportRoleId: 8 },
    name: 'bao-cao-doanh-thu',
    component: () => import(
      `@/views/Reports/bao-cao-doanh-thu.vue`
    )
  },
  {
    path: '/bao-cao/thong-ke-hoa-hong-nhan-vien/',
    meta: { title: 'Thống kê hoa hồng nhân viên', breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 4, reportRoleId: 8 },
    name: 'thong-ke-hoa-hong-nhan-vien',
    component: () => import(
      `@/views/Reports/thong-ke-hoa-hong-nhan-vien.vue`
    )
  },
  {
    path: '/thanh-toan-ho/',
    meta: { breadcrumb: true, requiresAuth: true, refreshButton: true, createButton: true, createButtonText: 'Tạo đơn mới', roleId: 64 },
    name: 'PayServiceList',
    component: () => import(
      `@/views/PayService/list.vue`
    )
  },
  {
    path: '/thanh-toan-ho/:Pid',
    meta: { title: 'Chi tiết thanh toán hộ', breadcrumb: true, requiresAuth: true, saveButton: false, roleId: 64 },
    name: 'PayServiceDetail',
    component: () => import(
      `@/views/PayService/detail.vue`
    )
  },
  {
    path: '/thu-chi-kho-tq/',
    meta: { title: 'Thu chi kho TQ', breadcrumb: true, createButton: true, requiresAuth: true, refreshButton: true, roleId: 2048 },
    name: 'StoreReportList',
    component: () => import(
      `@/views/StoreReport/list.vue`
    )
  },
  {
    path: '/thu-chi-kho-tq/:Pid',
    meta: { title: 'Chi tiết thu chi', breadcrumb: true, requiresAuth: true, saveButton: true, roleId: 2048 },
    name: 'StoreReportDetail',
    component: () => import(
      `@/views/StoreReport/detail.vue`
    )
  },
  {
    path: '/hashtag-don-hang/',
    meta: { title: 'Hashtag đơn hàng', breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 32 },
    name: 'hashtag-don-hang',
    component: () => import(
      `@/views/Reports/kiem-soat-don-hang.vue`
    )
  },
  {
    path: '/tin-nhan-nap-tien/',
    meta: { breadcrumb: true, requiresAuth: true, refreshButton: true, roleId: 8192 },
    name: 'BankMessages',
    component: () => import(
      `@/views/BankMessages/list.vue`
    )
  },
];
