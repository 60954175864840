export default [
  {
    title: 'Dashboard',
    group: 'apps',
    icon: 'fa-tachometer-alt',
    name: 'Dashboard',
    roleId: 1,
  },
  {
    title: 'Hashtag đơn hàng',
    group: 'apps',
    icon: 'fa-tag',
    badge: true,
    name: 'hashtag-don-hang',
    orderSystem: 1,
    roleId: 32
  },
  {
    title: 'Báo cáo',
    group: 'bao-cao',
    icon: 'fa-chart-line',
    name: 'Report',
    roleId: 4,
    items: [
      {
        title: 'Công nợ',
        name: 'bao-cao-cong-no',
        component: 'bao-cao-cong-no',
        roleId: 8
      },
      {
        title: 'Doanh thu tổng',
        name: 'bao-cao-tong-hop',
        component: 'bao-cao-tong-hop',
        roleId: 8
      },
      {
        title: 'Doanh thu theo khách',
        name: 'bao-cao-loi-nhuan-khach-hang',
        component: 'bao-cao-loi-nhuan-khach-hang',
        orderSystem: 1,
        roleId: 8
      },
      {
        title: 'Lợi nhuận đặt hàng',
        name: 'thong-ke-loi-nhuan-dat-hang',
        component: 'thong-ke-loi-nhuan-dat-hang',
        orderSystem: 1,
        roleId: 1,
      },
      {
        title: 'Chênh lệch tỷ giá',
        name: 'thong-ke-tien-te',
        component: 'thong-ke-tien-te',
        orderSystem: 1,
        roleId: 8,
      },
      {
        title: 'Thống kê nạp tiền',
        name: 'bao-cao-nap-tien',
        component: 'bao-cao-nap-tien',
        roleId: 8
      }
    ]
  },
  {
    title: 'Đơn hàng',
    group: 'apps',
    icon: 'fa-shopping-cart',
    name: 'OrderList',
    roleId: 32,
    orderSystem: 1,
    badge: false,
    children: [
      { name: 'orderdetail', title: 'Chi tiết đơn hàng', component: 'OrderDetail' }
    ]
  },
  {
    title: 'Thanh toán hộ',
    group: 'apps',
    icon: 'fa-credit-card',
    name: 'PayServiceList',
    roleId: 64,
    orderSystem: 1,
    badge: false,
    children: [
      { name: 'PayServiceDetail', title: 'Chi tiết thanh toán hộ', component: 'PayServiceDetail' }
    ]
  },
  {
    title: 'Kế toán',
    group: 'ke-toan',
    icon: 'fa-calculator',
    name: 'Accountant',
    roleId: 128,
    items: [
      {
        title: 'Thanh toán',
        component: 'PayOrderList',
        orderSystem: 1,
        roleId: 1
      },
      {
        title: 'Kiểm soát đơn hàng',
        component: 'AccountantOrder',
        orderSystem: 1,
        roleId: 1
      },
      {
        title: 'Tài chính',
        name: 'bao-cao-tai-chinh',
        component: 'bao-cao-tai-chinh',
        roleId: 16,
      },
      {
        title: 'Shop hoàn tiền KN',
        component: 'TuiKuanList',
        orderSystem: 1,
        roleId: 1
      },
    ]
  },  
  {
    title: 'Khách hàng',
    group: 'apps',
    icon: 'fa-user',
    name: 'CustomerList',
    roleId: 8,
    children: [
      { name: 'CustomerDetail', title: 'Chi tiết khách hàng', component: 'CustomerDetail'  }
    ]
  },
  {
    title: 'Tin SMS',
    group: 'apps',
    icon: 'fa-sms',
    name: 'BankMessages',
    roleId: 8192
  },
  {
    title: 'Nạp tiền',
    group: 'apps',
    icon: 'fa-wallet',
    name: 'RechargeNew',
    roleId: 16
  },
  {
    title: 'Rút tiền',
    group: 'apps',
    icon: 'fa-wallet',
    name: 'WithdrawHistory',
    roleId: 8   
  },
  {
    title: 'Khiếu nại',
    group: 'apps',
    icon: 'fa-exclamation-circle',
    name: 'Complain',
    roleId: 8,
    orderSystem: 1,
    children: [
      { name: 'ComplainDetail', title: 'Chi tiết khiếu nại', component: 'ComplainDetail' }
    ]
  },
  {
    title: 'Mã bill',
    cn_title: '单号',
    group: 'apps',
    icon: 'fa-cubes',
    name: 'Transaction',
    roleId: 512
  },
  {
    title: 'Thu chi kho TQ',
    cn_title: '收支对照表',
    group: 'apps',
    icon: 'fa-info-circle',
    name: 'StoreReportList',
    depositSystem: 1,
    roleId: 2048
  }, 
  {
    title: 'Nhập kho TQ',
    cn_title: '中国入仓',
    group: 'apps',
    icon: 'fa-warehouse',
    name: 'StoreManager',
    depositSystem: 1,
    roleId: 1024
  },
  {
    title: 'Bao hàng',
    cn_title: '包裹编号',
    group: 'apps',
    icon: 'fa-box',
    name: 'BigPackage',
    depositSystem: 1,
    roleId: 1024
  },
  {
    title: 'Xuất kho TQ',
    cn_title: '中国出仓库',
    group: 'xuat-kho-tq',
    icon: 'fa-store',
    name: 'ChinaExport',
    roleId: 1024,
    depositSystem: 1,
    // items: [
    //   {
    //     name: 'ChinaBigpackageExport',
    //     title: 'Xuất bao',
    //     component: 'ChinaBigpackageExport',
    //   },
    //   {
    //     name: 'ChinaSmallpackageExport',
    //     title: 'Xuất kiện',
    //     component: 'ChinaSmallpackageExport'
    //   }
    // ]
  },
  {
    title: 'Nhập kho VN',
    group: 'nhap-kho',
    icon: 'fa-store',
    name: 'VNImport',
    roleId: 4096,
    items: [
      {
        name: 'StoreManagerVN', 
        title: 'Nhập bao', 
        component: 'StoreManagerVN',
        depositSystem: 1
      },
      {
        name: 'SmallpackageImportVN', 
        title: 'Nhập kiện', 
        component: 'SmallpackageImportVN'
      }
    ]
  },
  {
    title: 'Xuất kho VN',
    group: 'xuat-kho',
    icon: 'fa-dolly-flatbed',
    name: 'VNExport',
    roleId: 256,
    items: [
      {
        name: 'Deliverable', 
        title: 'Mã bill', 
        component: 'Deliverable',
        orderSystem: 1,
        roleId: 1
      },
      {
        name: 'ExportReceipt', 
        title: 'Phiếu xuất kho', 
        component: 'ExportReceipt',
        roleId: 1
      },
      {
        name: 'StoreExport', 
        title: 'Xuất kho', 
        component: 'StoreExport',
        roleId: 1
      }
    ]
  },
  {
    title: 'Thông báo ZNS',
    group: 'apps',
    icon: 'fa-sms',
    name: 'ZNSNotification',
    roleId: 16384
  },
  {
    title: 'Cài đặt',
    group: 'cai-dat',
    icon: 'fa-cogs',
    name: 'Setting',
    roleId: 2,
    items: [
      {
        name: 'Configuration', 
        title: 'Cấu hình chung', 
        component: 'Configuration',
        roleId: 128
      },
      {
        title: 'Kho hàng TQ',
        name: 'ChinaStoreList',
        component: 'ChinaStoreList', 
        children: [
          { name: 'ChinaStoreDetail', title: 'Chi tiết kho TQ', component: 'ChinaStoreDetail' }
        ],
      },
      {
        title: 'Kho hàng VN',
        name: 'WarehouseList',
        component: 'WarehouseList', 
        children: [
          { name: 'WarehouseDetail', title: 'Chi tiết kho', component: 'WarehouseDetail' }
        ]
      },
      {
        title: 'Nhân viên',
        name: 'StaffList',
        component: 'StaffList', 
        children: [
          { name: 'StaffDetail', title: 'Chi tiết nhân viên', component: 'StaffDetail' }
        ],
        roleId: 128
      },
      {
        name: 'PricesList', 
        title: 'Bảng giá vận chuyển', 
        component: 'PricesList', 
        children: [
          { name: 'PricesDetail', title: 'Chi tiết bảng giá', component: 'PricesDetail' }
        ]
      }
    ]
  }
];
